interface Response {
  product_reviews: JunipProductReviewWithCustomer[]
}

export const useJunipReviews = () => {
  const config = useRuntimeConfig()

  return $fetch<Response>(
    'https://api.juniphq.com/v1/product_reviews?include=customer',
    {
      headers: {
        'Junip-Store-Key': config.public.junip.storeKey,
      },
    },
  ).then((res) => res.product_reviews.filter(({ rating }) => rating >= 4))
}
