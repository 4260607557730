<template>
  <div class="relative">
    <TransitionGroup :css="false" appear @enter="onEnter" @leave="onLeave">
      <div
        v-for="(image, index) in currentImages"
        :key="(currentIndex + index) % images.length"
        class="image group absolute inset-0 rotate-12 scale-[0.95] transform-gpu overflow-hidden rounded-[10px] bg-black transition-transform duration-700 last:rotate-0 last:scale-100 last:transition-none"
      >
        <PayloadImage
          v-if="image.image"
          :image="image.image"
          :sizes="sizes"
          class="h-full w-full object-cover opacity-80 transition-opacity duration-700 group-last:opacity-100"
        />
        <div
          v-if="image.name"
          class="absolute inset-0 top-auto px-5 py-4 md:py-5 xl:px-7 xl:py-6"
        >
          <p v-text="image.name" />
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import type { Image } from '#payload/types'
import type { SrcsetSizes } from '@/components/payload/image/types'

interface PhotoStackProps {
  images: {
    image?: string | Image | null
    name?: string
  }[]
  sizes?: SrcsetSizes
}

const props = defineProps<PhotoStackProps>()
const currentIndex = ref(0)

const images = computed(() => [...props.images, ...props.images])

let intervalId: NodeJS.Timeout

onMounted(() => {
intervalId = setInterval(() => {
  currentIndex.value =
    props.images.length > 1 ? (currentIndex.value + 1) % images.value.length : 0
}, 3000)
})

onBeforeUnmount(() => {
  clearInterval(intervalId)
})

const currentImages = computed(() => [
  images.value[
    (currentIndex.value === 0 ? images.value.length : currentIndex.value) - 1
  ],
  images.value[currentIndex.value],
])

const { $anime } = useNuxtApp()

function onEnter(targets: Element, complete: () => void) {
  $anime({
    targets,
    opacity: [0, 1],
    translateY: ['15%', 0],
    translateZ: 0,
    duration: 750,
    easing: 'cubicBezier(0.4, 0, 0.2, 1)',
    complete() {
      targets.removeAttribute('style')
      complete()
    },
  })
}

function onLeave(targets: Element, complete: () => void) {
  $anime({
    targets,
    scale: [0.95, 0.8],
    opacity: [1, 0],
    rotate: [12, 12],
    duration: 750,
    easing: 'cubicBezier(0.4, 0, 0.2, 1)',
    begin: () => {
      $anime.set(targets, { transitionDuration: '0ms' })
    },
    complete,
  })
}
</script>
