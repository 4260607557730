<template>
  <div class="space-y-4 rounded-[10px] bg-white px-5 py-4">
    <h4 class="text-[16px]/[30px]">{{ fullName }}</h4>
    <div class="space-y-2">
      <div class="flex space-x-0.5 text-orange">
        <SvgGlyphStar
          v-for="n in 5"
          :key="n"
          :outline="n > props.review.rating"
        />
      </div>
      <h3
        class="line-clamp-2 text-[24px]/[24px] font-medium"
        v-text="props.review.title"
      />
    </div>
    <div class="space-y-">
      <p
        ref="body"
        :class="showOverflow ? '' : 'line-clamp-4'"
        class="text-[16px]/[20px]"
        v-text="props.review.body"
      />
      <BaseButton
        v-if="hasOverflow && !showOverflow"
        class="text-black/50 hover:text-black"
        @click="showOverflow = true"
      >
        Read more
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface JunipReviewCardProps {
  review: JunipProductReviewWithCustomer
}

const props = defineProps<JunipReviewCardProps>()

const fullName = computed(() => {
  return [
    props.review.customer.first_name,
    props.review.customer.last_name,
  ].join(' ')
})

const body = ref<HTMLElement | null>(null)
const hasOverflow = ref(false)
const showOverflow = ref(false)

onMounted(() => {
  hasOverflow.value = body.value!.scrollHeight > body.value!.clientHeight
})
</script>
