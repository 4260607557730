<template>
  <div ref="container" class="keen-slider relative h-full w-full">
    <div v-for="review in reviews" :key="review.id" class="keen-slider__slide">
      <JunipReviewCard :review="review" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useKeenSlider } from 'keen-slider/vue'
import type { KeenSliderOptions, KeenSliderPlugin } from 'keen-slider'
import Timer from 'tiny-timer'

const { hasTouch } = useTouch()

const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    nextTick(slider.update)
  })

  slider.on('created', () => {
    observer.observe(slider.container)
  })

  slider.on('destroyed', () => {
    observer.unobserve(slider.container)
  })
}

const duration = 5000

const defaultSliderOptions: KeenSliderOptions = {
  drag: true,
  loop: true,
  defaultAnimation: {
    duration,
    easing: (t) => t,
  },
  slides: {
    origin: 'center',
    perView: 'auto',
    spacing: 20,
  },
}

const AutoplayPlugin: KeenSliderPlugin = (slider) => {
  let timer: Timer
  let timeout: NodeJS.Timeout
  let isHovering = false

  function animateNextSlide() {
    clearTimeout(timeout)

    if (isHovering) return

    timer = new Timer()
    timer.start(duration)
    slider.next()

    timeout = setTimeout(() => {
      animateNextSlide()
    }, duration)
  }

  function onMouseEnter() {
    isHovering = true
    clearTimeout(timeout)

    timer.pause()
    slider.animator.stop()
  }

  function onMouseLeave() {
    isHovering = false

    const currentSlideIndex = slider.track.details.abs
    const nextSlideIndex =
      timer.time < duration / 2 ? currentSlideIndex : currentSlideIndex + 1

    timer.resume()

    slider.moveToIdx(nextSlideIndex, true, {
      duration: timer.time,
      easing: (t) => t,
    })

    setTimeout(animateNextSlide, timer.time)
  }

  watch(hasTouch, (newValue) => {
    if (newValue) {
      slider.update(defaultSliderOptions)
      slider.container.removeEventListener('mouseenter', onMouseEnter)
      slider.container.removeEventListener('mouseleave', onMouseLeave)
      clearTimeout(timeout)
    } else {
      slider.update({
        ...defaultSliderOptions,
        drag: false,
      })
      slider.container.addEventListener('mouseenter', onMouseEnter)
      slider.container.addEventListener('mouseleave', onMouseLeave)

      setTimeout(animateNextSlide, 100)

      slider.on('destroyed', () => {
        clearTimeout(timeout)
      })
    }
  })
}

const reviews = ref(await useJunipReviews())

const [container] = useKeenSlider(defaultSliderOptions, [
  ResizePlugin,
  AutoplayPlugin,
])
</script>

<style lang="postcss" scoped>
.keen-slider__slide {
  @apply min-w-[300px] max-w-[300px] md:min-w-[320px] md:max-w-[320px];
}
</style>
